import {
  Accessor,
  createSignal,
  onMount,
  Setter,
  Show,
  Suspense,
} from "solid-js";
import { applyGiftCard } from "~/server/apis/client_apis";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";
import { useModal } from "./modal";
import WelcomeMessage from "./welcome-message";
import { ThreeDotLoader } from "~/widgets/button";
import { DottedLoader } from "~/widgets/loader";
import { getClientIdFromCookie } from "~/utils/common";

export type RedeemCodeModalProps = {
  userName: string;
  onClose?: () => void;
  setRefreshCoins: Setter<boolean>;
  isClientAxis: Accessor<boolean | undefined>;
};

export function RedeemCodeModal(props: RedeemCodeModalProps) {
  const [error, setError] = createSignal<string | null>(null);
  const [loading, setLoading] = createSignal(false);
  const { setIsModalOn, updateModalContentGetter, setOuterFrameClass } =
    useModal()!;
  const [textFieldValue, setTextFieldValue] = createSignal("");

  let inputRef: HTMLInputElement | undefined;

  const getCleanedInputLength = () => {
    return textFieldValue().replaceAll("-", "").length;
  };

  const redeemCode = async () => {
    setLoading(true);
    const clientId = getClientIdFromCookie();
    try {
      const cardNum = inputRef?.value.replaceAll("-", "") ?? "";
      const response = await applyGiftCard(cardNum, clientId);
      if (response.transactionStatus === "SUCCESS") {
        routeToSuccessModal(response.amount);
        if (props.setRefreshCoins) {
          props.setRefreshCoins(true);
        }
      }
    } catch (err: any) {
      setError(err.message ?? "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  function routeToSuccessModal(amount: number) {
    setIsModalOn(false);
    updateModalContentGetter(() => <></>);
    setOuterFrameClass("md:w-[380px] md:h-[516px]");
    updateModalContentGetter(() => {
      return (
        <WelcomeMessage
          coinsCredited={amount}
          userName={props.userName}
          title={"Congratulations"}
          subtitle={"You have successfully added Hubble coins to your account"}
          showDummy={false}
          isClientAxis={props.isClientAxis}
        />
      );
    });
    setIsModalOn(true);
  }

  function backRouting() {
    setIsModalOn(false);
    updateModalContentGetter(() => <></>);
    setOuterFrameClass("");
    if (props.onClose) {
      props.onClose();
    }
  }

  onMount(() => {
    inputRef?.focus();
  });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class=" flex  h-full w-full flex-col content-start justify-start rounded-t-2xl border border-neutral-200 bg-white pt-5   font-[Inter]  md:h-full lg:rounded-b-2xl ">
        <form
          class="my-auto md:my-0"
          onsubmit={(event) => {
            event.preventDefault();
          }}
        >
          <div class="px-6 pb-2 ">
            <PhosphorIcon
              name="arrow-left"
              fontSize={20}
              size="bold"
              class={`cursor-pointer pb-3 md:pb-1 ${loading() ? "text-textNormal" : "text-textDark"}`}
              onClick={() => {
                if (!loading()) {
                  backRouting();
                }
              }}
            />

            <div class="text-h3 text-textDark">Redeem code</div>
            <div class="text-medium text-textNormal">to add Hubble coins</div>
            <div class="pb-2 pt-5">
              <input
                type="text"
                ref={inputRef}
                value={textFieldValue()
                  .replaceAll("-", "")
                  .replace(/(.{4})/g, "$1-")
                  .replace(/-$/, "")}
                title={"Enter code"}
                onInput={(e) => {
                  setTextFieldValue(e.currentTarget.value);
                  setError(null);
                }}
                required
                class={`m-[1px] block w-full rounded-xl border px-4 py-3 text-f16Bold placeholder:text-f16 focus:m-0 focus:border-2 focus:ring-black  disabled:pointer-events-none disabled:opacity-50 md:text-mediumBold md:placeholder:text-medium ${error() ? "border-errorDark" : "focus:border-black"}`}
                style={{
                  "box-shadow": "0px 2px 11px 0px rgba(0, 0, 0, 0.05)",
                }}
                placeholder={"Enter code"}
              />
            </div>
            <Show when={error()} fallback={<div class="h-4"></div>}>
              <div class="text-f12 text-errorDark"> {error()}</div>
            </Show>
          </div>
          <div class="hidden md:flex">
            <Divider></Divider>
          </div>
          <div class=" px-6 py-4">
            <button
              type="submit"
              class={` w-full rounded-full  py-3 text-buttonMedium text-white ${error() ? "bg-gray-400" : "bg-basePrimaryDark"}`}
              onclick={() => {
                if (!loading() && !error()) {
                  redeemCode();
                }
              }}
            >
              <Show
                when={!loading()}
                fallback={
                  <div class="flex h-6 content-center items-center justify-center">
                    <ThreeDotLoader color="#999" />
                  </div>
                }
              >
                Redeem
              </Show>
            </button>
          </div>
        </form>
      </div>
    </Suspense>
  );
}
